import React, { useRef } from 'react';
import {
  CaretDownFilled,
  FieldTimeOutlined,
  LockOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, Avatar } from 'antd';

import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { formmatDateTime } from 'utils/date';
import ChangePasswordModal from '../ChangePasswordModal';

const PROFILE_MENU = [
  // {
  //   key: 'changePassword',
  //   text: 'header.changePassword',
  //   href: '/profile',
  // },
];

const UserInfo = () => {
  const { t } = useTranslation();
  const ref = useRef();
  const { logout, user } = useAuth();

  const logoutAction = () => {
    logout();
  };

  const menu = (
    <Menu style={{ minWidth: '120px' }}>
      {PROFILE_MENU.map((menu) => (
        <Menu.Item key={menu.href}>
          <a href={menu.href}>{t(menu.text)}</a>
        </Menu.Item>
      ))}
      <Menu.Item key="expired" icon={<FieldTimeOutlined />}>
        Expired:
        <b className="text-yellow">{` ${formmatDateTime(user?.endDate)}`}</b>
      </Menu.Item>
      <Menu.Item
        onClick={() => ref.current.setVisible(true)}
        key="changePassword"
        icon={<LockOutlined />}
      >
        Change Password
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={logoutAction} key="logout">
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="header__user-info">
        <Dropdown overlay={menu} trigger={['click']}>
          <div className="dropdown-user">
            <Avatar
              src={user?.avatar}
              size={30}
              icon={<UserOutlined />}
              className="avatar-user"
            />
            <CaretDownFilled className="arrow-down" />
          </div>
        </Dropdown>
      </div>
      <ChangePasswordModal ref={ref} />
    </>
  );
};

export default UserInfo;
