import { get, post, put } from './utils';

export async function getCurrentUserApi() {
  return get(`/user/me`);
}

export async function loginApi(data) {
  return post(`/auth/login`, data);
}
export async function loginFBApi(data) {
  return post(`/auth/facebook`, data);
}

export async function forgotPasswordApi(data) {
  return post(`/auth/forgotPassword`, data);
}

export async function resetPasswordApi(data) {
  return post(`/auth/resetPassword`, data);
}

export async function verifySSOKeyApi(data) {
  return post(`/auth/verify-sso-key`, data);
}

export async function changePasswordApi(data) {
  return put(`/user/me`, data);
}
