import { Form, Input, Modal, notification } from 'antd';
import { changePasswordApi } from 'api/auth';
import { useStore } from 'hooks/useStore';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

function ChangePasswordModal(props, ref) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [{ user }] = useStore();
  const [visible, setVisible] = useState(false);
  const { mutate, isLoading } = useMutation(
    (payload) => changePasswordApi(payload),
    {
      onSuccess: (res) => {
        form.resetFields();
        setVisible(false);
        notification.info({
          message: res?.message || 'Change Password Successfully!',
        });
      },
    },
  );
  const onFinish = (values) => {
    const { currentPassword, newPassword } = values;
    mutate({
      password: currentPassword,
      newPassword,
    });
  };

  useImperativeHandle(ref, () => ({ setVisible }), []);

  return (
    <div>
      <Modal
        title={t('users.changePassword')}
        visible={visible}
        onCancel={() => setVisible(false)}
        confirmLoading={isLoading}
        onOk={() => form.submit()}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            rules={[
              { required: true },
              {
                message: t('error.validatePassword'),
              },
            ]}
            name="currentPassword"
            required
            label={t('users.currentPassword')}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true },
              {
                message: t('error.validatePassword'),
              },
            ]}
            name="newPassword"
            required
            label={t('resetPassword.newPassword')}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            dependencies={['newPassword']}
            required
            label={t('users.confirmPassword')}
            hasFeedback
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              {
                message: t('error.validatePassword'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!',
                    ),
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default forwardRef(ChangePasswordModal);
