import { createContext, useContext, useReducer } from 'react';

export const initialState = {
  user: null,
  configs: null,
  isShowLoginModal: false,
  isAuthenticated: !!localStorage.getItem('sessionToken'),
};

export function reducer(state, action) {
  switch (action.type) {
    case 'showModal':
      state.isShowLoginModal = action.payload;
      return { ...state };
    case 'setUser':
      state.user = action.payload;
      state.isAuthenticated = true;
      return { ...state };
    case 'login':
      state.loading = true;
      return state;
    case 'loginSuccess':
      state.loading = false;
      state.isAuthenticated = true;
      localStorage.setItem('sessionToken', action?.payload?.token);
      return { ...state };
    case 'loginFail':
      state.loading = false;
      return state;
    case 'logout':
      state.user = null;
      state.isAuthenticated = false;
      localStorage.removeItem('sessionToken');
      return state;
    case 'setConfigs':
      state.configs = action.configs;
      return state;
    default:
      throw new Error();
  }
}

export const StoreContext = createContext({
  state: initialState,
  dispatch: () => {},
});

export const useStore = () => {
  const { state, dispatch } = useContext(StoreContext);
  return [state, dispatch];
};
