import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { Button, notification } from 'antd';
import { FacebookOutlined } from '@ant-design/icons';
import { useAuth } from 'hooks/useAuth';

const LoginFacebookBtn = ({ onCancel }) => {
  const { t } = useTranslation();
  const { loginFB } = useAuth();
  const [loadingFacebook, setLoadingFacebook] = useState(false);

  const responseFacebook = async (responseFacebook) => {
    // console.log(responseFacebook, 'responseFacebook');
    setLoadingFacebook(true);

    await loginFB({
      access_token: responseFacebook.accessToken,
    });

    setLoadingFacebook(false);
  };

  const onFailure = (payload) => {
    notification.error({
      message: t('error.title'),
      description: payload.error || t('error.description'),
    });
  };

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      render={(renderProps) => (
        <Button
          className="auth-actions__facebook"
          icon={<FacebookOutlined />}
          loading={loadingFacebook}
          onClick={renderProps.onClick}
          size="large"
          type="primary"
        >
          {t('loginFacebook')}
        </Button>
      )}
      onFailure={onFailure}
      callback={responseFacebook}
    />
  );
};

LoginFacebookBtn.propTypes = {
  onCancel: PropTypes.func,
};

export default LoginFacebookBtn;
