import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Space from 'antd/lib/space';
import Button from 'antd/lib/button';
import MobileMenuHeader from './MobileMenuHeader';

import { DrawerMenuMobileStyles } from './styles';
import ActionsDownloadMobile from './ActionsDownloadMobile';

const DrawerMenuMobile = forwardRef((_props, ref) => {
  const [visible, setVisible] = useState(false);

  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    openModal: () => {
      setVisible(true);
    },
  }));

  const onCancel = () => setVisible(false);

  const handleClickLogin = () => {};

  return (
    <DrawerMenuMobileStyles
      width="100vw"
      visible={visible}
      onClose={onCancel}
      title=""
    >
      <Space size={20} direction="vertical" className="space-wrapper-modal">
        <MobileMenuHeader direction="vertical" />

        <Button
          className="header-login-btn"
          type="primary"
          onClick={handleClickLogin}
        >
          {t('button.signIn')}
        </Button>
      </Space>

      {/* <ActionsDownloadMobile className="m-auto" /> */}
    </DrawerMenuMobileStyles>
  );
});

export default DrawerMenuMobile;
