import { createGlobalStyle } from 'styled-components';

const AntOverwriteStyle = createGlobalStyle`
  div::-webkit-scrollbar-thumb {
    border-radius: 3px !important;
    background:  var(--scrollbar-thumb)  !important;
  }
  div::-webkit-scrollbar-track {
    background:  var(--scrollbar-track) !important;
  }
  div::-webkit-scrollbar-thumb:hover {
    border-radius: 3px !important;
    background: var(--scrollbar-thumb)  !important;
  }
  div::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background:  var(--scrollbar-track) !important;
  }

  .loading {
    .ant-modal-content {
      background-color: transparent;
      box-shadow: none;
    }
  }
  .ant-slider-track {
    background-color: var(--color-primary);
  }
  .ant-slider-handle {
    border-color: var(--color-primary);
  }
  .ant-slider-rail {
    background-color: #e1e1e1 !important;
    height: 5px;
  }
  .ant-slider-handle {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color:var(--color-primary);
    border-color: var(--color-primary);
    transform: translate(-50%,-2px) !important;
  }

  @keyframes slideFromBottom {
    from {
      transform: translate(0px,100vh);
    }
    to {
      transform: translate(0px,0px);
    }
  }

  @keyframes noAnim {
    from {
    }
    to {
    }
  }

  @keyframes slideFromTopToBottom {
    from {
      transform: translate(0px,0px);
    }
    to {
      transform: translate(0px,100vh);
    }
  }

  @media (max-width: 768px) {
    .ant-dropdown {
      border-radius: 0px !important;
      top:0px !important;
      left:0px !important;
      right:0px !important;
      bottom:0px !important;
      z-index: 999;
      position:fixed;
      animation-name: slideFromBottom !important;
      animation-duration: 0.3s;
      animation-delay: 0s;
      transform: translate(0px,0px);
      transition: transform 0.3s;
      & >div {
        height:100vh;
        border-radius: 0px !important;
      }
      &.ant-slide-up-enter,&.ant-slide-up-leave-enter {
      animation-name: noAnim !important;
      }
      &.ant-slide-up-leave,&.ant-slide-up-leave-active {
      animation-name: noAnim !important;
      }
    }
    .ant-dropdown-hidden {
      transform: translate(0px,100vh);
      display:block !important;
      animation-name: slideFromTopToBottom !important;
    }
  }
  .ant-modal-header {
    border-bottom: 0px;
    .ant-modal-title {
      font-size:24px;
      font-weight: bold;
    }
  }

  .ant-form-item-label {
    label {
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      font-family: var(--font-family-poppins);
      color: var(--neutrals5);
    }
  }
    .ant-layout {
      background-color: var(--neutrals1);
    }
    .ant-btn {
      border-radius:20px;
    }

    .ant-tag {
      border: none;
    }
    .ant-pagination-item-active {
      background-color: var(--primary);
      color: var(--text-primary);
      &:hover {
        color: var(--text-primary);
      }
      a {
        color: var(--text-primary);
        &:hover {
          color: var(--text-primary);
        }
      }
    }
    .ant-select-arrow {
      .anticon {
        font-size: 20px;
        transform: translate(-5px,-4px);
      }
    }
    .ant-btn-ghost {
      background: var(--neutrals3);
    }
`;

export default AntOverwriteStyle;
