import 'antd/dist/antd.less';
import Layout from 'antd/lib/layout';
import PropTypes from 'prop-types';
import Header from './Header';
import LoginModal from './LoginModal';
import { PublicLayoutStyles } from './styles';

export default function PublicLayout({ children }) {
  return (
    <PublicLayoutStyles>
      <Header />
      <LoginModal />
      <Layout.Content>{children}</Layout.Content>
    </PublicLayoutStyles>
  );
}

PublicLayout.propTypes = {
  children: PropTypes.node,
};
