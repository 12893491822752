import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Space } from 'antd';
import LoginFacebookBtn from 'components/commons/LoginFacebookBtn';
import { useAuth } from 'hooks/useAuth';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function LoginModal() {
  const { showLoginModal, isAuthenticated, login, loading, isShowLoginModal } =
    useAuth();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const onFinish = (values) => {
    login(values).then(() => {});
  };

  useEffect(() => {
    console.log('isAuthenticated', isAuthenticated);
    isAuthenticated && showLoginModal(false);
  }, [isAuthenticated]);

  return (
    <Modal
      visible={isShowLoginModal}
      onOk={form.submit}
      confirmLoading={loading}
      onCancel={() => showLoginModal(false)}
      title={t('Login')}
      footer={null}
    >
      <Form
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
            autoComplete="none"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            autoComplete="new-password"
          />
        </Form.Item>
        <div className="d-flex">
          <LoginFacebookBtn />
          <Button
            size="large"
            type="primary"
            danger
            className="flex-1 ml-12"
            onClick={form.submit}
            loading={loading}
          >
            Login
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
