import React from 'react';
import Space from 'antd/lib/space';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const MENU = [
  {
    title: 'Products',
    href: '/',
  },
];

const MobileMenuHeader = ({ direction }) => {
  const { t } = useTranslation();

  return (
    <Space size={20} direction={direction} className="header-mobile-menu">
      {MENU.map((menu) => (
        <a href={menu.href} className={menu.href ? 'active' : ''}>
          {t(menu.title)}
        </a>
      ))}
    </Space>
  );
};

MobileMenuHeader.propTypes = {
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
};

export default MobileMenuHeader;
