import { createGlobalStyle } from 'styled-components';
import { CLASS_UTILITY } from 'utils/css';
import { CSS_RESET, CSS_COMMON } from './css';

const GlobalStyle = createGlobalStyle`
/* Variables */
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,500,600|DM+Sans:700");

:root {
  --neutrals1: rgba(19, 20, 22, 1);
  --neutrals2: rgba(34, 38, 46, 1);
  --neutrals3: rgba(53, 57, 69, 1);
  --primary: rgba(55, 114, 255, 1);
  --secondary: rgba(69, 178, 106, 1);
  --neutrals4: rgba(119, 126, 144, 1);
  --primary2: rgba(150, 86, 214, 1);
  --neutrals5: rgba(176, 180, 195, 1);
  --secondary4: rgba(205, 180, 219, 1);
  --secondary2: rgba(228, 214, 206, 1);
  --border-color: rgba(229, 232, 235, 1);
  --primary3: rgba(239, 70, 111, 1);
  --text-secondary: rgba(244, 245, 246, 1);
  --neutrals8: rgba(251, 252, 252, 1);
  --secondary3: rgba(255, 209, 102, 1);
  --text-primary: rgba(255, 255, 255, 1);

  --font-size-s: 12px;
  --font-size-m: 14px;
  --font-size-l: 16px;
  --font-size-xl: 24px;
  --font-size-xxl: 32px;
  --font-size-xxxl: 40px;
  --font-size-xxxxl: 64px;

  --font-family-dm_sans:"DM Sans";
  --font-family-poppins: "Poppins";
}



  body {
    background-color: var(--neutrals1);
    font-family: var(--font-family-poppins);
  }
  html {
    background-color: var(--neutrals1);
    scroll-behavior: smooth;
    font-family:var(--font-family-poppins);
  }
  
  * {
    font-family:var(--font-family-poppins);
  }

  body {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
  }

  /* styles wrapper container */
  .wrapper-container {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1550px;
    ${
      '' /* padding-left: 5%;
    padding-right: 5%; */
    }
    position: relative;
    width: 90%;
  
    ${
      '' /* @media only screen and (max-width: 425px) {
      width: calc(100% - 40px);
      padding-left: 20px;
      padding-right: 20px;
    } */
    }
  }

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
    .page {
      .content {
        min-height: 300px;
      }
    }
  }

  @media (max-width: 768px) {
    .container {
      max-width: 720px;
    }
  }

  @media (max-width: 992px) {
    .container {
      max-width: 960px;
    }
  }

  @media (max-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }
  @media (min-width: 1201px) {
    .container {
      max-width: 1140px;
    }
  }
  @media (min-width: 1441px) {
    .container {
      max-width: 1240px;
    }
  }
  .margin-section {
    margin: 18px 0 70px;
  }

  .landing-wrapper {
    padding-top: 20px;
  }
  .landing-padding {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .landing-divider {
    margin: 40px 0 !important;
  }
  /** ---------------- */

.d-desktop {
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
}

.d-mobile {
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}
.radio-group{
  width: 100%;
  .radio {
    display: flex;
    align-items: center;
    flex-flow: row-reverse;
    justify-content: space-between;
  }
}

  .ant-picker {
    background: var(--background-input);
    width: 100%;
  }
  .ant-drawer-header {
    .ant-drawer-title {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 22px;
    }
  }

  .ant-drawer-body {
    padding: 10px 0;
    .drawer-title-item {
      padding: 10px 24px;
      font-weight: 700;
    }
    .menu-item {
      margin: 0;
      padding: 10px 24px;
    }
  }

  
  .ant-layout,.ant-layout-content {
    max-width: 100vw;
    overflow-x: visible; 
  }
  
  ${
    '' /* a {
    color: #000;
    &:hover {
      color: ${({ theme }) => theme.palette.primary};
    }
  } */
  }
  
  .note {
    font-size: 12px;
    color: var(--text-note);
  }

  .description {
    font-size: 14px;
    color: var(--text-description);
  }


  .text-secondary {
    color:  var(--text-secondary);
  }

  .text-primary {
    color: var(--text-primary);
  }

  .text-highlight-secondary {
    color: var(--secondary);
  }

  .text-yellow {
    color: var(--secondary3);
  }


  .sub-title {
    font-weight: 400;
  }

  .label {
    background-color: var(--background-light);
    border-radius: 10px;
    text-align:center;
    display:inline-flex;
    justify-content: center;
    align-items:center;
    padding: 5px 20px;
  }
  
  h1 {
    font-size: 40px;
    font-weight: bold;
  }

  .btn-gray {
    background: var(--background-gray) !important;
    border: 1px solid  var(--background-gray) !important;
  }

  .btn-dark-gray {
    background:  var(--background-darkGraySecond) !important;
    border: 1px solid var(--background-darkGraySecond)  !important;
  }

  .btn-gray-second {
    background:  var(--background-graySecond) !important;
    border: 1px solid  var(--background-graySecond) !important;
  }

  .btn-light-f5 {
    background: #F5F5F5 !important;
    border-color: #F5F5F5 !important;
  }
  
  .btn-light {
    background: #F9F9F9 !important;
    border-color: #F9F9F9 !important;
  }

  .overlay-popover-marker {
    .ant-popover-inner {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
    }
  }
  
  .overlay {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: 1000;
    background: rgba(255,255,255,100);
  }

  .hiddenCheckbox {
    display: none;
  }

  h2 {
    font-size: 20px;
    font-weight:bold;
  }
  /* START Fix input autofill background */
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px var(--background-input) inset !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    background: transparent;
  }
  /* END Fix input autofill background */
  .sticky {
    position: sticky;
    top: 0;
  }
  .shadow {
    box-shadow:  var(--shadow);
    background:white;
  }
  .ellipsis-3-t {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  .ellipsis-2-t {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  .ellipsis-t {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .light-bg {
    background: var(--background-light);
  }

  .dark-bg {
    background: var(--background-black);
  }

  /** START custom scrollbar only hover */

.custom-scrollbar {

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent !important;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background:  var(--scrollbar-thumb) !important;
    }
  }
}
/** END custom scrollbar only hover */

/* START fix style Preview Image */
.ant-image-preview-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-image-preview-mask {
  background: black !important;
  
}
.ant-image-preview-operations {
  .anticon-rotate-left, .anticon-rotate-right {
    display: none;
  }
}

  /* END fix style Preview Image */
.bg-gray {
  background-color: var(--neutrals2);
}  
.p-10 {
  padding: 10px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.following-btn {
  background: #32C12F !important;
  color: #fff !important;
  border-color: #32C12F !important;
}
.d-flex {
  display:flex;
}

/* Classes */

.title {
  color: var(--neutrals8);
  font-family: var(--font-family-dm_sans);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
}
.collection-item-name {
  color: var(--neutrals4);
  font-family: var(--font-family-dm_sans);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.collection-total {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  letter-spacing: 0.00px;
  font-weight: 600;
  font-style: normal;
}
.collection-by {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  letter-spacing: 0.00px;
  font-weight: 700;
  font-style: normal;
}
.collection-divider {
  border-width: 2px;
  border-style: solid;
  border-color: var(--neutrals3);
}
.collection-author {
  color: var(--text-secondary);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-weight: 600;
  font-style: normal;
}
.border-2px-neutrals6 {
  border-width: 2px;
  border-style: solid;
  border-color: var(--border-color);
}
.body-2 {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  letter-spacing: 0.00px;
  font-weight: 400;
  font-style: normal;
}
.dmsans-bold-alabaster-14px {
  color: var(--neutrals8);
  font-family: var(--font-family-dm_sans);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.dmsans-bold-alabaster-32px {
  color: var(--neutrals8);
  font-family: var(--font-family-dm_sans);
  font-size: var(--font-size-xxl);
  font-weight: 700;
  font-style: normal;
}
.caption-bold {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  letter-spacing: 0.00px;
  font-weight: 500;
  font-style: normal;
}
.dmsans-bold-alabaster-64px {
  color: var(--neutrals8);
  font-family: var(--font-family-dm_sans);
  font-size: var(--font-size-xxxxl);
  font-weight: 700;
  font-style: normal;
}
.dmsans-bold-alabaster-48px {
  color: var(--neutrals8);
  font-family: var(--font-family-dm_sans);
  font-size: 48px;
  font-weight: 700;
  font-style: normal;
}
.border-2px-neutrals1 {
  border-width: 2px;
  border-style: solid;
  border-color: var(--neutrals1);
}
.caption-2-bold {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  letter-spacing: 0.00px;
  font-weight: 600;
  font-style: normal;
}
.border-2px-primary4 {
  border-width: 2px;
  border-style: solid;
  border-color: var(--secondary);
}
.border-2px-neutrals4 {
  border-width: 2px;
  border-style: solid;
  border-color: var(--neutrals4);
}
.hairline-2 {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  letter-spacing: 0.00px;
  font-weight: 700;
  font-style: normal;
}
.body-2-bold {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  letter-spacing: 0.00px;
  font-weight: 500;
  font-style: normal;
}
.dmsans-bold-alabaster-16px {
  color: var(--neutrals8);
  font-family: var(--font-family-dm_sans);
  font-size: var(--font-size-l);
  font-weight: 700;
  font-style: normal;
}
.caption {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  letter-spacing: 0.00px;
  font-weight: 400;
  font-style: normal;
}
.caption-2 {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  letter-spacing: 0.00px;
  font-weight: 400;
  font-style: normal;
}
a {
  color var(--neutrals8);
}

.dmsans-bold-slate-gray-16px {
  color: var(--neutrals4);
  font-family: var(--font-family-dm_sans);
  font-size: var(--font-size-l);
  font-weight: 700;
  font-style: normal;
}

${CLASS_UTILITY}
${CSS_RESET}
${CSS_COMMON}
.delete {
  text
}
`;

export default GlobalStyle;
