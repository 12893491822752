import styled from 'styled-components';
import { Layout } from 'antd';

export const PublicLayoutStyles = styled(Layout)`
  min-height: 100vh;
  background-color: var(--neutrals1);
  .ant-layout-content {
    background-color: var(--neutrals1);
    margin-top: 64px;
  }
`;
