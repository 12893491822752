import React, { useRef, useEffect } from 'react';

// import PropTypes from 'prop-types';
import Layout from 'antd/lib/layout';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';

import { MenuOutlined, SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { HeaderStyles } from './styles';
import UserInfo from './UserInfo';
import DrawerMenuMobile from './DrawerMenuMobile';
import MenuHeader from './MenuHeader';
import DrawerSearchMobile from './DrawerSearchMobile';

const Header = () => {
  const mobileMenuModalRef = useRef();
  const mobileSearchRef = useRef();
  const { showLoginModal, isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();
  // const handleClick = () => {
  //   window.location.pathname = '/';
  // };

  const handleClickLogin = () => {
    showLoginModal(true);
  };

  const handleClickMenuIcon = () => {
    mobileMenuModalRef?.current && mobileMenuModalRef.current.openModal();
  };

  const openMobileSearch = () => {
    mobileSearchRef?.current && mobileSearchRef.current.openModal();
  };

  const onSearch = (e) => {
    window.location.href = `/products?q="${e}"`;
  };

  useEffect(() => {}, []);

  return (
    <>
      <HeaderStyles>
        <Layout.Header className="mainHeader w-full">
          <div className="header-wrapper w-full flex-center-between">
            <div className="header-left d-flex">
              <div className="header-logo">
                <a href="/">
                  <img alt="logo" src="/images/logo.png" />
                </a>
              </div>

              <Input.Search
                className="header-search-input"
                placeholder="Search..."
                size="large"
                onSearch={onSearch}
              />
            </div>

            <div className="header-right d-flex align-items-center">
              <MenuHeader />
              {/* <LanguageDropdown /> */}
              <Button
                onClick={openMobileSearch}
                className="mobile-menu-icon"
                type="text"
                size="large"
                icon={<SearchOutlined />}
              />

              {isAuthenticated ? (
                <UserInfo />
              ) : (
                <Button
                  className="header-login-btn"
                  type="primary"
                  onClick={handleClickLogin}
                >
                  Sign-in
                </Button>
              )}
              <Button
                onClick={handleClickMenuIcon}
                className="mobile-menu-icon"
                type="text"
                size="large"
                icon={<MenuOutlined />}
              />
            </div>
          </div>
        </Layout.Header>
      </HeaderStyles>
      <DrawerMenuMobile ref={mobileMenuModalRef} />
      <DrawerSearchMobile ref={mobileSearchRef} />
    </>
  );
};

Header.propTypes = {};

export default Header;
