import styled from 'styled-components';

const ActionsDownloadMobileStyles = styled.div`
  display: flex;

  img {
    max-width: 135px;
  }
  .coming-soon {
    text-align: right;
    margin-top: 13px;
  }
`;

export default ActionsDownloadMobileStyles;
