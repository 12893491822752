import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Space from 'antd/lib/space';
import Input from 'antd/lib/input';
import { SearchOutlined } from '@ant-design/icons';

import { DrawerMenuMobileStyles } from './styles';

const DrawerSearchMobile = forwardRef((_props, ref) => {
  const [visible, setVisible] = useState(false);

  const { t } = useTranslation();

  const onSearch = (e) => {
    window.location.href = `/products?q="${e}"`;
  };

  useImperativeHandle(ref, () => ({
    openModal: () => {
      setVisible(true);
    },
  }));

  const onCancel = () => setVisible(false);

  return (
    <DrawerMenuMobileStyles
      width="100vw"
      visible={visible}
      onClose={onCancel}
      title="Search"
    >
      <Input.Search
        className="header-search-input"
        placeholder="Search..."
        size="large"
        onSearch={onSearch}
      />
    </DrawerMenuMobileStyles>
  );
});

export default DrawerSearchMobile;
